import codeCombination from '@/app/components/shared/codeCombination';
import TransactionAttribute from '../transactionAttribute';
import appStrings from '@/app/utility/string.utility.js';
import DatePicker from 'vue2-datepicker';
import store from '../../../../../../store';
export default {
  name: 'ViewTransaction',
  props: ['trxHdrId','referenceTrxId'],
  components: {
    codeCombination,
    TransactionAttribute,
    DatePicker
  },
  data() {
    return {
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      compositeCheck: false,
      billHdrId: null,
      distributionDetails: {},
      distributionList: [],
      distributionFeilds: [
        {
          key: 'trx_number'
        },
        {
          key: 'accounting_class',
          label: 'Charge Type'
        },
        {
          key: 'ccid_code',
          label: 'Code Combination'
        },
        {
          key: 'gl_date',
          label: 'GL Date',
          class: 'text-center'
        },
        {
          key: 'transaction_dr',
          label: 'Trx Amt DR',
          class: 'text-right'
        },
        {
          key: 'transaction_cr',
          label: 'Trx Amt CR',
          class: 'text-right'
        },
        {
          key: 'base_dr',
          class: 'text-right'
        },
        {
          key: 'base_cr',
          class: 'text-right'
        },
        {
          key: 'accounting_event_type',
          label: 'Accounting Event'
        },
        {
          key: 'narration',
          class: 'text-right'
        },
        {
          key: 'period_name'
        },
        {
          key: 'posting_flag',
          label: 'Posting'
        },
        {
          key: 'gl_date'
        }
        // {
        //   key: 'charge_type'
        // },
        // {
        //   key: 'ccid',
        //   label: 'CCID'
        // },
        // {
        //   key: 'gl_date',
        //   class: 'text-center'
        // },
        // {
        //   key: 'period'
        // },
        // {
        //   key: 'accounting_status',
        //   label: 'Accounting'
        // },
        // {
        //   key: 'posting_status',
        //   label: 'Posting'
        // },
        // {
        //   key: 'trx_amount_dr',
        //   label: 'Trx Amt DR',
        //   class: 'text-right'
        // },
        // {
        //   key: 'trx_amount_cr',
        //   label: 'Trx Amt CR',
        //   class: 'text-right'
        // },
        // {
        //   key: 'amount_dr',
        //   class: 'text-right'
        // },
        // {
        //   key: 'amount_cr',
        //   class: 'text-right'
        // },
        // {
        //   key: 'narration',
        //   class: 'text-right'
        // }
      ],
      transactionDetails: {
        trx_number: null,
        trx_type: null,
        gl_date: null,
        trx_date: null,
        currency_id: null,
        currency: null,
        exchange_rate: null,
        source_hdr_id: null,
        source_name: null,
        customer: null,
        customer_id: null,
        customer_site: null,
        customer_site_id: null,
        bill_to_address_id: null,
        bill_to_address: null,
        le_name: null,
        ou_name: null,
        module_name: null,
        fp_reg_num: null,
        tp_reg_num: null,
        location_name: null,
        value_date: null,
        trx_amount: null,
        party_id: null,
        address_id: null,
        address: null,
        trx_status_code: null,
        trx_status_meaning: null,
        narration: null,
        period_start_date: null,
        period_end_date: null,
        exchange_rate_date: null,
        unit_criteria: null,
        unit_name: null,
        debtor_account: null,
        transaction_line: [],
        accounting_status: null
      },
      transactionLineList: [],
      transactionLineFields: [
        {
          key: 'doc_num'
        },
        {
          key: 'line_num'
        },
        {
          key: 'line_type'
        },
        {
          key: 'comp_group_name',
          label: 'Component Group'
        },
        {
          key: 'comp_name'
        },
        {
          key: 'trx_amount',
          class: 'text-center'
        },
        {
          key: 'sub_unit_name',
          label: 'Sub Unit'
        },
        {
          key: 'unit_name'
        },
        {
          key: 'floor_name'
        },
        {
          key: 'tower_name'
        },
        {
          key: 'prj_name',
          label: 'Project Name'
        },
        {
          key: 'narration'
        },
        {
          key: 'code_combination'
        },
        {
          key: 'code_combination_meaning',
          label: 'Meaning',
          stickyColumn: true
        }
      ],
      lineInnerDetailsList: [],
      lineInnderDetailsFeilds: [
        {
          key: 'charge_type'
        },
        {
          key: 'ccid',
          label: 'CCID'
        },
        {
          key: 'gl_date'
        },
        {
          key: 'hdr_period',
          label: 'Period'
        },
        {
          key: 'accounting_status',
          label: 'Accounting'
        },
        {
          key: 'posting_status',
          label: 'Posting'
        },
        {
          key: 'trx_amount_dr',
          label: 'Trx Amt DR'
        },
        {
          key: 'trx_amount_cr',
          label: 'Trx Amt CR'
        },
        {
          key: 'amount_dr'
        },
        {
          key: 'amount_cr'
        },
        {
          key: 'narration'
        }
      ],
      irnGenerated: null,
      showCodeCombinationModal: false,
      accountType: null,
      combinationDetails: {
        le_id: null,
        project_code: null
      },
      segmentIndex: null,
      showAttributeModal: false,
      reason: null,
      date: null,
      isReveseSuccess: false,
      showReveseAlert: false,
      reverseResponseMsg: '',
      // reverseResponseText: null,
      validateMsg: null,
      requestId: null,
      templateId: null,
      deleteBill: false,
      showValueSetModal: false,
      vsetCode: null,
      parent_value_set_id: null,
      receiptMethodName: {
        value: null,
        text: null
      },
      projectId: null,
      defaultValue: {
        value: null,
        text: null
      }
    };
  },
  mounted() {
    if (this.trxHdrId || this.referenceTrxId) {
      this.getTransactionDetails();
      this.getEinvoiceDetailsById();
    }
    this.eventBus.$on('getTransactionDetails', transactionDetails => {
      this.billHdrId = transactionDetails.trx_hdr_id;
      this.getTransactionLine(transactionDetails.trx_hdr_id);
    });
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.billHdrId });
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'delete') {
          this.$bvModal.show('cancel-modal');
        }
        if (actionName === 'save' && this.editMode) {
          if (this.transactionDetails.trx_status_code !== 'COMPLETE') {
            this.addEditArTransactionHdr();
            this.addEditArTransactionDtl();
          } else {
            alert('Transaction Status is Complete');
          }
        }
      }
    });
  },
  methods: {
    addEditArTransactionHdr() {
      const payload = {
        trx_hdr_id: this.trxHdrId,
        debtor_ccid: this.transactionDetails.debtor_ccid,
        narration: this.transactionDetails.narration,
        ar_prj_receipt_method_mpng_id: this.receiptMethodName.value
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/addEditArTransactionHdr', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getTransactionDetails();
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditArTransactionDtl() {
      const trxDetails = this.transactionDetails.transaction_line.map(elem => {
        return {
          charge_ccid: elem.charge_ccid,
          narration: elem.narration,
          trx_dtl_id: elem.trx_dtl_id
        };
      });
      const payload = {
        trx_details: trxDetails
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/addEditArTransactionDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getTransactionDetails();
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showHideCodeCombinationModal(flag, name, index) {
      this.accountType = name;
      this.segmentIndex = index;
      if (flag) {
        if (!this.transactionDetails.le_id) {
          return alert(appStrings.DEPENDENTVSETMSG.LEMSG);
        }
        if (!this.transactionDetails.project_code) {
          return alert(appStrings.DEPENDENTVSETMSG.PROJECTMSG);
        }
        this.combinationDetails.le_id = this.transactionDetails.le_id;
        this.combinationDetails.project_code = this.transactionDetails.project_code;
      }
      this.showCodeCombinationModal = flag;
    },
    selectedSegment(item) {
      if (this.accountType === 'debtor') {
        this.transactionDetails.debtor_ccid = item.ccid;
        this.transactionDetails.debtor_account = item.segment_code;
        this.transactionDetails.debtor_account_meaning = item.segment_meaning;
      } else if (this.accountType === 'charge') {
        this.transactionDetails.transaction_line[
          this.segmentIndex
        ].charge_ccid = item.ccid;
        this.transactionDetails.transaction_line[
          this.segmentIndex
        ].code_combination = item.segment_code;
        // this.transactionDetails.transaction_line[
        //   this.segmentIndex
        // ].code_combination_meaning = item.segment_meaning;
      }
      this.showHideCodeCombinationModal(false);
    },
    getTransactionDetails() {
      this.loader = true;
      this.$store
        .dispatch('receivables/getArTransactionDetails', this.trxHdrId ? this.trxHdrId : this.referenceTrxId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.transactionDetails = resp.data.data;
            this.projectId = resp.data.data.project_id;
            this.receiptMethodName = {
              value: this.transactionDetails.ar_prj_receipt_method_mpng_id,
              text: this.transactionDetails.ar_prj_receipt_method_mpng_name
            };
            // const trxDtlsData = this.transactionDetails.transaction_line
            // for (let i = 0; i < trxDtlsData.length; i++) {
            //   this.getCodeCombinationNew(trxDtlsData[i])
            // }
            // //
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    getCodeCombinationNew(item, index) {
      const payload = {
        trxDtlId: item.trx_dtl_id
      };
      this.loader = true;
      this.$store
        .dispatch('receivables/getCodeCombinationNew', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.transactionDetails.transaction_line[
              index
            ].code_combination_meaning =
              response.data.data[0].code_combination_meaning;
            // this.tempArr.push({ id: item.trx_dtl_id, meaning: result }),
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getTransactionLine(trxHdrId) {
      this.loader = true;
      this.$store
        .dispatch('fms/getFMSTransactionLineDetails', trxHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const trxDetails = response.data.data.transaction_details.map(
              detail => {
                detail.lineInnerDetailsList = [];
                return detail;
              }
            );
            this.transactionLineList = trxDetails;
            this.transactionDetails = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // getDistribution() {
    //   const payload = {
    //     bill_hdr_id: this.billHdrId,
    //     composite: false
    //   };
    //   this.$store.dispatch('fms/getFMSDistribution', payload).then(response => {
    //     if (response.status === 200) {
    //       this.distributionDetails = response.data.data;
    //       this.distributionList = response.data.data.trx_distributions;
    //     }
    //   });
    // },
    // tempFunc(){
    //   if(this.trxHdrId){
    //   this.getInvoiceAccountingList(this.trxHdrId)}
    // },
    getInvoiceAccountingList() {
      this.loader = true;
      this.$store
        .dispatch('receivables/getAccountingDetails', this.trxHdrId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.distributionList = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getLineDetailsById(trxDtlId, index) {
      if (this.transactionLineList[index].lineInnerDetailsList.length === 0) {
        this.loader = true;
        this.$store
          .dispatch('fms/getFMSTransactionLineDataByDtlId', trxDtlId)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.transactionLineList[index].lineInnerDetailsList =
                response.data.data;
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getEinvoiceDetailsById() {
      const payload = {
        module_id: this.$store.state.shared.moduleId,
        trx_id: this.trxHdrId ? this.trxHdrId : this.referenceTrxId
      };
      this.loader = true;
      this.$store
        .dispatch('fms/getEinvoiceDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.irnGenerated = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideAttributeModal(flag) {
      this.showAttributeModal = flag;
    },
    getRequestTemplateId() {
      if (this.reason && this.date) {
        const data = {
          trx_id: this.trxHdrId,
          cn_date: this.date,
          cn_reason: this.reason
        };
        const payload = {
          _page: 0,
          _limit: 10,
          template_name: 'GENERATE_CREDIT_NOTE_TMP'
        };
        this.$store
          .dispatch('template/getTemplateList', payload)
          .then(response => {
            if (response.status === 200) {
              this.loader = false;
              this.showReveseAlert = true;
              this.isReveseSuccess = true;
              this.reverseResponseMsg = response.data.message;
              this.requestId = response.data.data.page[0].request_id;
              this.templateId = response.data.data.page[0].template_id;
              this.saveSubmitRequest(data);
            } else {
              this.loader = false;
              this.showReveseAlert = true;
              this.isReveseSuccess = false;
              this.reverseResponseMsg = response.response.data.message;
            }
          })
          .catch(err => {
            this.loader = false;
            this.isReveseSuccess = false;
            this.showReveseAlert = true;
            this.reverseResponseMsg = err.message;
          });
      } else {
        alert('Please Fill Mandatory Fields');
      }
    },
    saveSubmitRequest(data) {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'BLANK', //pass hardcode
            no_of_args: 3,
            request_parameter: JSON.stringify(data),
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: JSON.stringify(data),
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.loader = false;
            this.showReveseAlert = true;
            this.isReveseSuccess = true;
            this.reverseResponseMsg = resp.data.message;
            this.requestNumId = resp.data.data[0].id;
            this.getTransactionDetails();
          } else {
            this.loader = false;
            this.showReveseAlert = true;
            this.isReveseSuccess = false;
            this.reverseResponseMsg = resp.resp.data.message;
          }
        })
        .catch(err => {
          this.loader = false;
          this.isReveseSuccess = false;
          this.showReveseAlert = true;
          this.reverseResponseMsg = err.message;
        });
    },
    // reverseTrx() {
    //   if(this.reason && this.date){
    //   const payload = {
    //     trx_id: this.trxHdrId,
    //     cn_date: this.date,
    //     cn_reason: this.reason
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('receivables/reverseTrx', payload)
    //     .then(response => {
    //       this.loader = false;
    //       if (response.status === 200) {
    //         this.isReveseSuccess = true;
    //         this.showReveseAlert = true;
    //         this.reverseResponseMsg = response.data.message;
    //         this.getTransactionDetails();
    //         // this.reverseResponseText = response.data.data;
    //       } else {
    //         this.isReveseSuccess = false;
    //         this.showReveseAlert = true;
    //         this.reverseResponseMsg = response.response.data.message;
    //       }
    //     })
    //     .catch(err => {
    //       this.loader = false;
    //       this.isReveseSuccess = false;
    //       this.showReveseAlert = true;
    //       this.reverseResponseMsg = err.message;
    //     });
    //   } else (
    //     alert('Please Fill Mandatory Fields')
    //   )
    // },
    hideCreateCreditModal() {
      this.reason = null;
      this.date = null;
      this.isReveseSuccess = false;
      this.showReveseAlert = false;
      this.reverseResponseMsg = '';
      // this.reverseResponseText = null;
    },
    getValidatePeriodDate(date) {
      // to get validate date
      const payload = {
        module_id: store.state.shared.moduleId,
        le_id: this.transactionDetails.le_id,
        entity_id: this.transactionDetails.ou_id,
        gl_date: date
      };
      this.loader = true;
      this.$store
        .dispatch('manualPayments/getValidatePeriodDate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.showReveseAlert = true;
            this.isReveseSuccess = true;
            this.reverseResponseMsg = `${response.data.message} , GL Date status is ${response.data.data}.`;
            this.validateMsg = response.data.data;
            if (this.validateMsg !== 'OPEN') {
              // this.glDate = format(new Date(), appStrings.DATEFNSFORMAT);
              this.date = null;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    cancelTrxData() {
      this.loader = true;
      const payload = {
        ar_trx_id: this.trxHdrId,
        flag: this.deleteBill ? 'Y' : 'N'
      };
      this.$store
        .dispatch('receivables/cancelTrxData', payload)
        .then(response => {
          this.loader = false;
          this.$bvModal.hide('cancel-modal');
          if (response.status === 200) {
            this.showAlert = true;
            this.isSuccess = true;
            this.$emit('onDeletion');
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    clearCancelFields() {
      this.deleteBill = false;
    },
    openValueSetModal(vsetCode) {
      // this function opens the selected valueSet
      this.showValueSetModal = true;
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.parent_value_set_id = this.legalEntity.value;
      }
      if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET
      ) {
        this.parent_value_set_id = this.projectId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (
        this.vsetCode ===
        appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET
      ) {
        this.receiptMethodName = {
          value: item.ar_proj_receipt_method_mpng_id,
          text: item.receipt_methods_name
        };
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = this.defaultValue;
      } else if (
        vsetCode === appStrings.VALUESETTYPE.MASTER_LEASE_RECEIVING_BANK_VSET
      ) {
        this.receiptMethodName = this.defaultValue;
      }
    }
  },
  destroyed() {
    this.unsubscribe();
    this.eventBus.$off('getTransactionDetails');
  }
};
